import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 intro-text">
                <h1 className="text-left">
                  {props.data ? props.data.eyebrow : "Loading"}
                  <span>{props.data ? props.data.title : "Loading"}</span>
                </h1>
                <div className="intro-copy text-left">
                  <svg className="visible-xs" xmlns="http://www.w3.org/2000/svg" width="170" height="21" viewBox="0 0 170 21" fill="none">
                    <path d="M6 21H170L164 0H0L6 21Z" fill="#F5E940"/>
                  </svg>
                  <p>
                    FR8logik is a team of industry experts, AI technologists, data scientists, and automation engineers. The sole aim is to unlock and activate untapped opportunity within logistics for shippers, freight agencies, 3PLs, and beyond.
                  </p>
                  <a className="btn btn-outline" href="#demo">
                    Schedule a Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
