import React from "react";
import { useEffect } from "react";

export const Navigation = (props) => {

  const navClick = () => {
    document.querySelector('#menu .navbar-collapse').classList.remove('in');
  };

  useEffect(() => {
    const faq = document.getElementById('faq');
    const onScroll = () => {
      const rect = faq.getBoundingClientRect();
      if (rect.top < 70 && rect.bottom > 70) {
        document.getElementById('menu').classList.add('inverted');
      }
      else {
        document.getElementById('menu').classList.remove('inverted');
      }
    };
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container-fluid">
        <div className="navbar-wrapper">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top">
              <svg width="167" height="59" viewBox="0 0 167 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group">
                  <g id="Group_2">
                    <path id="Vector" d="M17.831 31.9681H6.81674V43.251H0V14.9431H18.9391V20.2151H6.81674V26.7296H17.831V31.9681Z" fill="#F5E940"/>
                    <path id="Vector_2" d="M30.2891 33.2441H26.5953V43.251H19.7786V14.9431H30.9271C34.2851 14.9431 36.9044 15.6818 38.7849 17.1593C40.6653 18.6368 41.6056 20.7524 41.6056 23.4724C41.6056 25.4536 41.2026 27.0654 40.3967 28.375C39.5908 29.6847 38.3483 30.7256 36.6693 31.5316L42.5794 42.9487V43.251H35.259L30.2891 33.2441ZM26.5953 28.0057H30.8936C32.2032 28.0057 33.177 27.6699 33.815 26.9983C34.453 26.3267 34.7888 25.3528 34.7888 24.144C34.7888 22.9351 34.453 21.9613 33.7814 21.2561C33.1098 20.5509 32.136 20.2151 30.86 20.2151H26.5953V28.0057Z" fill="#F5E940"/>
                    <path id="Vector_3" d="M62.4252 22.4986C62.4252 23.8754 62.0894 25.0842 61.4178 26.1252C60.7462 27.1662 59.8059 28.0057 58.5971 28.6437C59.9403 29.3153 61.0148 30.222 61.7872 31.3637C62.5931 32.5054 62.9625 33.8822 62.9625 35.4269C62.9625 37.9454 62.0894 39.9602 60.3432 41.4041C58.5971 42.8816 56.2129 43.5868 53.1571 43.5868C50.1013 43.5868 47.65 42.848 45.9038 41.3705C44.1241 39.893 43.251 37.9118 43.251 35.3933C43.251 33.8822 43.654 32.539 44.4263 31.3637C45.1986 30.1884 46.3068 29.2817 47.7171 28.6101C46.5083 27.9721 45.568 27.1326 44.8964 26.0916C44.2248 25.0506 43.8554 23.8418 43.8554 22.465C43.8554 20.0137 44.6949 18.066 46.3739 16.6221C48.0529 15.2117 50.3028 14.473 53.1235 14.473C55.9778 14.473 58.2613 15.1781 59.9403 16.6221C61.5857 18.0996 62.4252 20.0472 62.4252 22.4986ZM56.4144 34.9232C56.4144 33.7479 56.1121 32.8748 55.5077 32.2368C54.9033 31.5987 54.0973 31.2965 53.1235 31.2965C52.1497 31.2965 51.3438 31.5987 50.7393 32.2368C50.1349 32.8748 49.8327 33.7479 49.8327 34.9232C49.8327 36.0649 50.1349 36.938 50.7393 37.6096C51.3438 38.2476 52.1497 38.5834 53.1571 38.5834C54.1309 38.5834 54.9033 38.2476 55.5077 37.6096C56.1121 36.9715 56.4144 36.0649 56.4144 34.9232ZM53.1235 19.6107C52.2504 19.6107 51.5788 19.9129 51.1087 20.4838C50.6386 21.0546 50.4035 21.8606 50.4035 22.868C50.4035 23.8754 50.6386 24.6813 51.1087 25.3193C51.5788 25.9573 52.2504 26.2595 53.1571 26.2595C54.0638 26.2595 54.7354 25.9573 55.1719 25.3193C55.6084 24.6813 55.8435 23.8754 55.8435 22.868C55.8435 21.8606 55.6084 21.0546 55.1383 20.4838C54.7018 19.8793 54.0302 19.6107 53.1235 19.6107Z" fill="#F5E940"/>
                  </g>
                  <g id="Group_3">
                    <path id="Vector_4" d="M69.8128 59H60.8805L79.4502 0H88.4161L69.8128 59Z" fill="#F5E940"/>
                  </g>
                  <g id="Group_4">
                    <path id="Vector_5" d="M93.9232 43.251H87.3415V13.3984H93.9232V43.251Z" fill="white"/>
                    <path id="Vector_6" d="M95.1321 32.539C95.1321 30.457 95.535 28.5765 96.341 26.9311C97.1469 25.2857 98.3222 24.0432 99.8669 23.1366C101.412 22.2635 103.191 21.827 105.24 21.827C108.396 21.827 110.881 22.8008 112.694 24.7484C114.508 26.6961 115.414 29.3489 115.414 32.7069V32.9419C115.414 36.2328 114.508 38.8184 112.694 40.7325C110.881 42.6465 108.396 43.6204 105.307 43.6204C102.318 43.6204 99.9004 42.7137 98.0871 40.934C96.2738 39.1542 95.3 36.7029 95.1657 33.6471L95.1321 32.539ZM101.68 32.9419C101.68 34.8896 101.982 36.2999 102.587 37.2066C103.191 38.1133 104.098 38.5834 105.273 38.5834C107.59 38.5834 108.799 36.8036 108.833 33.2106V32.539C108.833 28.778 107.624 26.8975 105.206 26.8975C103.023 26.8975 101.815 28.5094 101.647 31.7666L101.68 32.9419Z" fill="white"/>
                    <path id="Vector_7" d="M115.549 32.5726C115.549 30.457 115.885 28.5765 116.59 26.9311C117.295 25.2857 118.302 24.0433 119.612 23.1366C120.922 22.2635 122.433 21.7934 124.179 21.7934C126.361 21.7934 128.074 22.5322 129.317 24.0097L129.552 22.1964H135.495V42.4115C135.495 44.2584 135.059 45.8702 134.219 47.247C133.346 48.6238 132.104 49.6648 130.492 50.4035C128.846 51.1423 126.966 51.5117 124.817 51.5117C123.272 51.5117 121.795 51.2094 120.351 50.6386C118.907 50.0677 117.799 49.2954 117.026 48.3551L119.78 44.4935C120.989 45.9374 122.601 46.6426 124.615 46.6426C127.503 46.6426 128.981 45.1651 128.981 42.1764V41.5048C127.738 42.8816 126.126 43.5868 124.212 43.5868C121.66 43.5868 119.578 42.613 118 40.6318C116.422 38.6841 115.616 36.0313 115.616 32.7405V32.5726H115.549ZM122.13 32.9755C122.13 34.6881 122.466 36.0649 123.104 37.0723C123.742 38.0797 124.649 38.5834 125.824 38.5834C127.235 38.5834 128.276 38.1133 128.914 37.173V28.3079C128.276 27.3341 127.268 26.864 125.858 26.864C124.682 26.864 123.776 27.4012 123.104 28.4422C122.466 29.4832 122.13 30.9943 122.13 32.9755Z" fill="white"/>
                    <path id="Vector_8" d="M137.174 16.79C137.174 15.8497 137.51 15.0774 138.182 14.5065C138.853 13.9021 139.726 13.5999 140.801 13.5999C141.875 13.5999 142.748 13.9021 143.42 14.5065C144.092 15.111 144.427 15.8833 144.427 16.79C144.427 17.7302 144.092 18.5025 143.42 19.0734C142.748 19.6778 141.875 19.98 140.801 19.98C139.726 19.98 138.853 19.6778 138.182 19.0734C137.51 18.5025 137.174 17.7302 137.174 16.79ZM144.125 43.251H137.544V22.2299H144.125V43.251Z" fill="white"/>
                    <path id="Vector_9" d="M154.636 35.4268L152.789 37.2737V43.251H146.241V13.3648H152.789V29.2146L153.46 28.3415L158.397 22.1963H166.221L158.867 30.86L166.725 43.251H159.236L154.636 35.4268Z" fill="white"/>
                  </g>
                </g>
              </svg>
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="#about-us" className="page-scroll" onClick={navClick}>
                  About Us
                </a>
              </li>
              <li>
                <a href="#features" className="page-scroll" onClick={navClick}>
                  Features
                </a>
              </li>
              <li>
                <a href="#faq" className="page-scroll" onClick={navClick}>
                  FAQ
                </a>
              </li>
              <li>
                <a href="#demo" className="page-scroll btn btn-custom" onClick={navClick}>
                  Schedule a Demo
                </a>
              </li>
              <li className="login">
                <a href="https://securityportal.fr8logik.com/user/login" target="_blank" onClick={navClick}>
                  Login
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
