import React from "react";

export const Faq = (props) => {
  return (
    <div id="faq" className="text-left">
      <div className="container">
        <div className="section-title">
          <h2>Frequently Asked Questions</h2>
          <p>Please contact us if you cannot find an answer to your question.</p>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {props.data
              ? props.data.map((d, i) => (
                <div key={`faq-${i}`} className="panel panel-default">
                  <div className="panel-heading" role="tab" id={`heading-${i}`}>
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" href={`#collapse-${i}`} aria-expanded={(i === 0) ? true : false} aria-controls={`collapse-${i}`} className={(i === 0) ? '' : 'collapsed'}>
                        {d.question}
                      </a>
                    </h4>
                  </div>
                  <div id={`collapse-${i}`} className={(i === 0) ? 'panel-collapse collapse in' : 'panel-collapse collapse'} role="tabpanel" aria-labelledby={`heading-${i}`}>
                    <div className="panel-body">
                      {d.answer}
                    </div>
                  </div>
                </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};
