import React from "react";

export const About = (props) => {
  return (
    <div id="about-us">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <h3 className="eyebrow">About Us</h3>
            <h2>{props.data ? props.data.feature : "loading..."}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 col-md-offset-6">
            <p>{props.data ? props.data.paragraph : "loading..."}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
