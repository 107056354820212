import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-left">
      <div className="container">
        <div className="row">
          <div className="col-md-12 section-title">
            <h3 className="eyebrow">Features</h3>
            <h2>Industry Targeted Solutions</h2>
          </div>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-md-4 feature">
                  {" "}
                  <div className="icon">
                    <img src={`/img/${d.icon}`} />
                  </div>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
